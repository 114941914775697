.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,1);
    color: #fff;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }
