@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap');

.App {
  width: 100%;
  overflow-x: hidden;
  -webkit-touch-callout:none {}
  -webkit-user-select:none {}
  -khtml-user-select:none {}
  -moz-user-select:none {}
  -ms-user-select:none {}
  user-select:none {}
  &.lang-en * {
    letter-spacing: 0!important;
    line-height: 1.4!important; }
  &.lang-en {
    .navbar {
      .navbar-nav {
        .nav-link {
          font-size: 16px; } } }
    .dropdown-item {
      font-size: 16px; }
    .homeNumber {
      .circle-box {
        .other-circle {
          .text {
            font-size: 12px;
            white-space: nowrap;
            line-height: 1.2!important;
            letter-spacing: -0.5px!important; } } } }
    .carousel-item {
      h1 {
        font-size: 22px; } }
    h2.aboutStepH2 {
      font-size: 20px;
      padding-bottom: 10px; }
    .aboutStep {
      .d-lg-none {
          .text-center:first-child {
              h2.aboutStepH2 {
                  padding-bottom: 0;
                  margin-bottom: -5px; } } } }
    .circle-wrap {
      .circle-inner {
        font-size: 12px;
        div {
          line-height: 1.2!important; } } }
    .service-item {
      &>div>div>div:nth-child(4) {
        .circle-wrap {
          .circle-inner {
            div {
              padding-top: 6px; } } } } } } }

@media (max-width: 630px) {
  .App {
    &.lang-en {
      .homeWhy {
        .heading {
          h2 {
            width: 278px;
            margin-left: auto;
            margin-right: auto; } } } } } }



@media (max-width: 575px) {
  .App {
    &.lang-en {
      .contact-wrap {
        .heading {
          &.container {
            padding-left: 0;
            padding-right: 0; }
          div {
            p {
              max-width: 100%; } } } } } } }

@media (min-width: 576px) {
  .App {
    &.lang-en {
      .carousel-item {
        h1 {
          font-size: 26px; } }
      .homeNumber {
        .circle-box {
          .other-circle {
            .text {
              font-size: 15px; } } } }
      .circle-wrap {
        .circle-inner {
          font-size: 13px;
          padding-left: 10px;
          padding-right: 10px; } } } } }

@media (min-width: 768px) {
  .App {
    &.lang-en * {
      letter-spacing: 0!important;
      line-height: 1.6!important; }
    &.lang-en {
      .homeNumber {
        .circle-box {
          .other-circle {
            .text {
              font-size: 16px; } } } } } } }

@media (min-width: 992px) {
  .App {
    &.lang-en {
      .carousel-item {
        h1 {
          font-size: 36px; } }
      .navbar {
        .navbar-nav {
          .nav-link {
            font-size: 16px;
            padding-left: 15px;
            padding-right: 15px;
            &:before {
              left: 15px; }
            &:hover:before, &.active:before {
              width: calc(100% - 30px); }
            .sub-menu {
              width: 380px; } } } }
      .circle-wrap {
        .circle-inner {
          font-size: 14px; } } } } }

@media (min-width: 1199px) {
  .App {
    &.lang-en {
      .navbar {
        .navbar-nav {
          .nav-link {
            font-size: 16px;
            padding-left: 22px;
            padding-right: 22px;
            &:before {
              left: 22px; }
            &:hover:before, &.active:before {
              width: calc(100% - 44px); }
            .sub-menu {
              width: 380px;
              .nav-link {
                font-size: 16px; } } } } } } } }

body {
  font-size: 16px;
  color: #000;
  font-family: 'Noto Sans TC', 'Noto Sans SC', sans-serif;
  font-weight: 400;
  @media (max-width: 991px) {
    padding-top: 68px; } }

h1 {
  font-size: 30px; }

h2 {
  font-size: 22px; }

.p1 {
  font-size: 14px; }

.p2 {
 }  // font-size: 20px

.p3 {
  font-size: 12px;
  padding-left: 0;
  padding-right: 0; }

.section {
  padding-top: 25px;
  padding-bottom: 25px;
  @media (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px; }
  @media (min-width: 992px) {
    padding-top: 50px;
    padding-bottom: 50px; } }

.container {
  padding-left: 25px;
  padding-right: 25px; }

.bg-grey {
  background-color: #f8f8f8; }

.bg-blue {
  background-color: #eefaff; }

.s1 {
  padding-top: 40px;
  padding-bottom: 40px; }

.s11 {
  padding-top: 20px;
  padding-bottom: 20px; }

.s111 {
  padding-top: 30px;
  padding-bottom: 30px; }

input::placeholder,
textarea::placeholder {
  color: #bebebe!important; }

textarea {
  min-height: 80px; }

.form-group {
  font-size: 14px;
  color: #000;
  background-size: 21.7px;
  background-position: left 8px top 6.5px;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  min-height: calc(1.5em + .75rem + 2px);
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .25rem;
  position: relative;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &.form-name {
    background-image: url('../img/contact/ico/name.png'); }
  &.form-company {
    background-image: url('../img/contact/ico/company.png'); }
  &.form-tel {
    background-image: url('../img/contact/ico/tel.png'); }
  &.form-email {
    background-image: url('../img/contact/ico/email.png'); }
  &.form-content {
    background-image: url('../img/contact/ico/content.png'); }
  &:after {
      content: "";
      position: absolute;
      top: 7px;
      left: 39px;
      width: 1px;
      height: 20px;
      background-color: #cdcdcd; }
  .form-control {
    margin-right: 0;
    padding-left: 50px;
    padding-right: 15px;
    width: 100%;
    font-size: 14px;
    background: transparent;
    border: 1px solid #ced4da;
    &:focus {
      box-shadow: none;
      border-color: #158cff;
      color: #000; } } }

button[type="submit"], .btn {
  background-color: #002d6a;
  color: #fff;
  width: 130px;
  padding: 7px 7px 8px 7px;
  border-radius: 5px;
  border: none;
  margin-top: 15px;
  box-shadow: none!important;
  &:hover {
    color: #fff;
    background-color: #0069d9; } }

.news-details-wrap {
  background-color: #f8f8f8; }

@media (min-width: 768px) {
  h2 {
    font-size: 27px; }
  .p3 {
    font-size: 14px; }
  .form-group {
    background-size: 24.8px;
    background-position: left 10px top 7px;
    &:after {
      top: 9px;
      left: 44px;
      width: 1px;
      height: 22px; }
    .form-control {
      height: calc(1.8em + .75rem + 2px);
      padding-left: 55px; } }
  textarea {
    min-height: 120px;
    padding-top: 0.5rem!important; } }
@media (min-width: 992px) {
  .news-details-wrap {
    margin-top: 92.05px; }
  h2 {
    font-size: 28px; } }
